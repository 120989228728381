import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function TermsAndConditionsPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TERMSANDCONDITIONS}>
      <Seo
        title="Terms & Conditions | Robotic Imaging"
        description='"Robotic Imaging is here to accelerate your architectural projects. Contact us to help streamline your process."'
      />

      <header className="site__header"></header>
      <main className="content">
        <div className="terms-and-conditions">
          <div className="terms-and-conditions-header">
            <h2>Robotic Imaging's Terms &amp; Conditions of Use</h2>
          </div>
          <div className="terms-and-conditions-policy-container">
            <p>Last Updated: July 15, 2024</p>
            <p>
              <strong>
                PLEASE READ THESE TERMS &amp; CONDITIONS CAREFULLY BEFORE USING
                THIS SITE. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS &amp;
                CONDITIONS, PLEASE DO NOT ACCESS THIS SITE, USE THE SITE, OR
                INDICATE ACCEPTANCE OF THESE TERMS.
              </strong>
            </p>
            <p>
              These Terms &amp; Conditions (“Terms” or “Terms &amp; Conditions”)
              constitute an agreement between you (“you” or the “user”) and
              Robotic Imaging, Inc. and its affiliates, subsidiaries or assigns
              (collectively, “Robotic Imaging,” “we,” “us” or “our”). This
              website{" "}
              <a
                href="https://www.roboticimaging.com/"
                style={{ color: "#ffb310" }}
              >
                https://www.roboticimaging.com/
              </a>{" "}
              (the “Site”), our platform (the “Platform,”) and our application
              (“Application”) are owned and operated by Robotic Imaging. Access
              and use of this Site, our Application and our services provided
              through the Site, Application, and offline (together, the
              “Services”) are provided by Robotic Imaging to you on condition
              that you accept these Terms &amp; Conditions. By accessing or
              using the Services, you signify that you have read, understand,
              and agree to be bound by these Terms &amp; Conditions. If you do
              not agree to accept these Terms &amp; Conditions, you may not
              access or use the Services.
            </p>
            <p>
              <strong>ARBITRATION NOTICE:</strong> EXCEPT FOR CERTAIN TYPES OF
              DISPUTES DESCRIBED IN THE “ARBITRATION PROVISION AND CLASS ACTION
              WAIVER” SECTION BELOW, AND UNLESS YOU OPT-OUT AS SET FORTH IN THAT
              SAME SECTION, YOU AGREE THAT DISPUTES BETWEEN YOU AND ROBOTIC
              IMAGING OR OTHER PARTIES DESCRIBED HEREIN WILL BE RESOLVED BY
              BINDING, INDIVIDUAL ARBITRATION AT THE ELECTION OF EITHER PARTY,
              AND YOU WAIVE YOUR RIGHT TO A JURY TRIAL AND YOUR RIGHT TO BRING
              OR RESOLVE ANY DISPUTE AS OR TO PARTICIPATE IN A CLASS, PRIVATE
              ATTORNEY GENERAL, REPRESENTATIVE, OR COLLECTIVE ACTION IN COURT OR
              IN ARBITRATION.
            </p>
            <p>
              We reserve the right at any time to change: (i) the terms and
              conditions of these Terms &amp; Conditions; (ii) the Services,
              including terminating, eliminating, supplementing, modifying,
              adding or discontinuing any content or feature or data or service
              on or available through the Services or the hours that they are
              available; (iii) any fees or charges, if any, related to the use
              of the Services; and (iv) the equipment, hardware or software
              required to use and access the Services.
            </p>
            <p>
              Any changes we make to these Terms &amp; Conditions will be
              effective immediately upon posting on this Site. Please return to
              this Site periodically to ensure you are familiar with the most
              current version of these Terms &amp; Conditions.
            </p>
            <ol>
              <li>
                <strong>YOU AGREE TO OUR PRIVACY POLICY</strong>
              </li>
              <p>
                Robotic Imaging has made a commitment to protecting the privacy
                of those who use the Services. Please review our Privacy Policy,
                which is incorporated by reference in these Terms &amp;
                Conditions. By using the Services, you are consenting to and
                agreeing to be bound by the Privacy Policy.
              </p>
              <p>
                When you use the Services, you consent to receive communications
                from us electronically via the Services, email, or otherwise.
                You agree to provide accurate, current, and complete information
                about yourself when you use the Services and to update such
                information while you continue to use the Services.
              </p>
              <p>
                To the extent permitted by law, Robotic Imaging reserves the
                right to automatically process information using technologies
                that use machine learning and/or artificial intelligence. You
                further acknowledge that by using certain aspects of the
                Services, you may be interacting with functions that involve the
                use of machine learning or artificial intelligence.
              </p>
              <li>
                <strong>USE OF THE SERVICES</strong>
              </li>
              <p>
                If you access the Services from outside of the United States,
                you do so at your own risk and are responsible for compliance
                with local, national, or international laws. In particular, you
                understand that this Site, the Services, or both may not be
                available in all countries and that you are responsible for
                ensuring that it is lawful for you to use this Site and receive
                the Services in your location.
              </p>
              <p>
                Some countries may have laws that forbid participating in the
                activities we offer on the Site or Services. These restrictions
                might include a minimum age for using the Internet or entering
                into contracts like these Terms &amp; Conditions. You are
                responsible for making sure you can use this Site and the
                Services in the location you reside in. Continuing to use the
                Site represents and warrants to us that you have verified that
                you are permitted to use the Site and Services in your
                jurisdiction.
              </p>
              <li>
                <strong>ELIGIBILITY</strong>
              </li>
              <p>
                You represent and warrant that you: (a) are above the legal age
                of majority in your jurisdiction of residence; (b) have not
                previously been suspended or removed from the Site or our
                Services; and (c) have full power and authority to enter into
                these Terms, and in doing so will not violate any other
                agreement to which you are a party.
              </p>
              <li>
                <strong>SERVICES AND YOUR ACCOUNT</strong>
              </li>
              <p>
                In order to access and use certain Services available on this
                Site, you may need to sign up for, open and maintain an account
                (your <strong>“Account”</strong>) with us. Prior to completing the signup process
                for your Account, you may be required to confirm your acceptance
                of all of the terms and conditions of these Terms &amp;
                Conditions. If you do not agree to these Terms &amp; Conditions,
                you may not sign up for an Account and you shall not have the
                right to use such Services.
              </p>
              <p>
                You represent and warrant that at all times you will: (i)
                provide accurate, current and complete information about
                yourself as prompted by our registration form or otherwise; and
                (ii) maintain and promptly update your information (including
                your e-mail address) to keep it accurate, current and complete.
                If you provide any information that is untrue, inaccurate, not
                current or incomplete, or if we have reasonable grounds to
                suspect that such information is untrue, inaccurate, not current
                or incomplete, we shall have the right to suspend or terminate
                your Account and any or all privileges on the Services and to
                refuse any and all current or future use of the Services.{" "}
              </p>
              <p>
                During the registration process, you may be required to choose a
                user name and enter your email address. You acknowledge and
                agree that Robotic Imaging may rely on this email address or
                user name to identify you. You shall be responsible for
                protecting the confidentiality of your user name(s),
                identification number(s), and/or password(s), if any. You are
                responsible for all use of your Account, regardless of whether
                you authorized such access or use, and for ensuring that all use
                of your Account complies fully with the provisions of these
                Terms &amp; Conditions. You must not choose a username that
                infringes the rights of any third party or which is offensive,
                racist, obscene, hurtful, unlawful, or otherwise inappropriate.
                You agree not to transfer your right to use or access this Site
                or the Services via your username or password to any third
                person.
              </p>
              <p>
                WE HEREBY DISCLAIM ANY AND ALL LIABILITY FOR ANY UNAUTHORIZED
                USE OF YOUR ACCOUNT FOR WHICH WE ARE NOT RESPONSIBLE.
              </p>
              <p>
                Any conduct that in our sole discretion restricts or inhibits
                anyone else from using or enjoying the Services will not be
                permitted. We reserve the right in our sole discretion to remove
                or edit any content and to terminate your Account for any
                reason.
              </p>
              <p>
                If you wish to delete your Account or terminate your
                subscription, you can do so at any time. In the event of
                termination, you will still be bound by your obligations under
                these Terms.
              </p>
              <li>
                <strong>MOBILE DEVICES</strong>
              </li>
              <p>
                If you use a mobile device to access the Services optimized for
                mobile viewing, or use a mobile application, the following
                additional terms and conditions also apply.
              </p>
              <p>
                You understand that wireless service through Wi-Fi or a
                participating mobile service provider may not be available in
                all areas at all times and may be affected by product, software,
                coverage, or other service changes made by your mobile service
                provider or otherwise. You agree that you are solely responsible
                for all message and data charges that apply to use of your
                mobile device to access the Site. All such charges are billed by
                and payable to your mobile service provider. Please contact your
                participating mobile service provider for pricing plans,
                participation status and details.
              </p>
              <li>
                <strong>ELECTRONIC MESSAGES</strong>
              </li>
              <p>
                By accessing our Site, signing up for Services, creating an
                account with us, or typing your name into any of our electronic
                forms and indicating your acceptance or submission of
                information by clicking a box, you consent to (i) us
                communicating with you electronically; (ii) receiving all
                applications, notices, disclosures, and authorizations from us
                (collectively, <strong>"Records"</strong>) electronically; and (iii) entering
                into agreements and transactions using electronic Records and
                signatures. Please note that federal law treats electronic
                signatures as having the same legal force and effect as if they
                were signed on paper by hand, and online contracts have the same
                legal force as signing an equivalent paper contract in ink. You
                agree that any notices, agreements, disclosures, or other
                communications that we send to you electronically will satisfy
                any legal communication requirements, including that such
                communications be in writing. You must have a computer or other
                web-enabled device, an internet connection, an active email
                account, and the ability to receive and read PDF files to
                conduct business with us electronically. You agree to be
                responsible for keeping your own Records. If you require
                assistance with your Records or if you wish to receive Records
                in paper format or to withdraw your consent to receiving
                electronic Records from us, please contact us at{" "}
                <a
                  href="mailto:info@roboticimaging.com"
                  style={{ color: "#ffb310" }}
                >
                  info@roboticimaging.com
                </a>
                . Agreements and transactions executed prior to this request
                will remain valid and enforceable.
              </p>
              <li>
                <strong>OUR MATERIALS</strong>
              </li>
              <p>
                Robotic Imaging may make certain digital reproductions of
                materials, information, content, software, digital scans, or
                other materials (including but not limited to intellectual
                property, database rights, graphics, videos, text, and logos)
                available to you from the Services from time to time
                (collectively, the <strong>“Robotic Imaging Materials”</strong>). The Services
                (including its organization, presentation and Robotic Imaging
                Materials) is the property of Robotic Imaging and its licensors
                and may be protected by intellectual property laws including
                laws relating to copyrights, trademarks, trade names, internet
                domain names and other similar rights. Robotic Imaging or its
                licensors own all related trademarks and logos, and you agree
                not to copy or use them in any manner except as permitted.
              </p>
              <p>
                By using the Services, we grant you a non-exclusive,
                non-transferrable, non-sublicensable right and license to use
                the Robotic Imaging Materials for your personal use only. If you
                download, access or use any Robotic Imaging Materials, you agree
                that such materials: (i) may only be used for your personal use;
                (ii) may not be modified, used to create a derivative work,
                incorporated into any other work or otherwise exploited without
                Robotic Imaging’s prior written permission; (iii) shall not be
                submitted to, processed by, or otherwise fed into any artificial
                intelligence or automated processing software; and (iv) shall
                only be used in compliance with any additional license terms
                accompanying such materials.
              </p>
              <li>
                <strong>USER CONTENT</strong>
              </li>
              <p>
                You are solely responsible for the content and information
                (collectively referred to as <strong>“User Content”</strong>) that you post or
                upload on or that you transfer to us in relation to the Services
                including feedback or questions. By submitting User Content to
                the Services, you automatically grant Robotic Imaging the
                royalty-free, perpetual, worldwide, irrevocable, non-exclusive
                right and license, but not the obligation, to use, publish,
                reproduce, modify, adapt, edit, translate, create derivative
                works from, incorporate into other works, distribute,
                sub-license and otherwise exploit such User Content (in whole or
                in part) worldwide in any form, media or technology now known or
                hereafter developed for the full term of any copyright that may
                exist in such User Content, without payment to you or to any
                third parties. You acknowledge and agree that this license
                includes the right to reproduce User Content, including any
                personal information or your likeness, for marketing and
                advertising purposes.
              </p>
              <p>
                User Content uploaded or otherwise communicated on the Services
                shall comply with all applicable law and regulations and any
                applicable third party agreements, which you are responsible for
                knowing and understanding. You are prohibited from posting,
                transferring, or otherwise communicating to us, or any other
                user of the Services:
              </p>
              <ol type="a">
                <li>
                  any offensive, inaccurate, incomplete, abusive, obscene,
                  profane, threatening, defamatory, intimidating, harassing,
                  racially offensive, or illegal material, or any material that
                  infringes or violates another person’s rights (including
                  intellectual property rights, and rights of privacy and
                  publicity);
                </li>
                <li>
                  any violent, vulgar, obscene, pornographic, or otherwise
                  sexually explicit User Content;
                </li>
                <li>
                  User Content that harms or reasonably can be expected to harm
                  any person or entity; infringes on any right of a third party
                  including (i) copyright, patent, trademark, trade secret or
                  other proprietary or contractual rights, (ii) rights of
                  privacy (specifically, you must not distribute another
                  person’s personal information of any kind without their
                  express permission) personality or publicity; or (iii) any
                  confidentiality obligation;
                </li>
                <li>
                  User Content that is illegal or encourages or advocates
                  illegal activity or the discussion of illegal activities with
                  the intent to commit them, including a submission that is, or
                  represents an attempt to engage in, child pornography,
                  stalking, sexual assault, fraud, trafficking in obscene or
                  stolen material, harassment, theft, or conspiracy to commit
                  any criminal activity;
                </li>
                <li>
                  anything that (i) does not generally pertain to the designated
                  topic or theme of the Site or the Services; (ii) violates any
                  specific restrictions applicable to the Site or the Services,
                  including age restrictions and procedures; or (iii) is
                  antisocial, disruptive, or destructive, including anything
                  that can be construed as “spamming,” “flooding,” or
                  “trolling,” as those terms are commonly understood and used on
                  the Internet; or
                </li>
                <li>
                  anything that: (i) expresses or implies that any statements
                  you make or actions you take are endorsed by us; (ii)
                  impersonates another person (including celebrities); (iii)
                  indicates that you are a Robotic Imaging employee,
                  representative of Robotic Imaging; or (iv) attempts to mislead
                  users by indicating that you represent Robotic Imaging or any
                  of Robotic Imaging’s partners or affiliates.
                </li>
              </ol>
              <p>
                You understand and agree that we, without any obligation to do
                so, may monitor or review any User Content you post, upload or
                otherwise communicate through or related to your use of the
                Services. We reserve the right to remove any such User Content,
                in whole or in part, at our sole discretion, that violates these
                Terms, is likely to harm our reputation, is deemed inappropriate
                or otherwise poses a risk of harm to any other person. We
                reserve the right to deactivate your account or otherwise
                prevent your use and/or participation on Services at any time
                and for any reason.
              </p>
              <p>
                You agree that you have all necessary rights and permissions
                related to your User Content, including rights and permissions
                for Services involving processing using artificial intelligence
                and machine learning.
              </p>
              <p>
                You agree that we may access, preserve and disclose your account
                and any User Content posted, uploaded, received or otherwise
                communicated if required to do so by law or with the good faith
                belief that such access, preservation or disclosure is
                reasonably necessary to: (i) comply with an enforceable subpoena
                or other legal process; (ii) enforce these Terms; (iii) respond
                to claims that any information violates the rights of third
                parties; (iv) respond to your requests for customer service or
                allow you to use the Services in the future; or (v) protect the
                rights, property or personal safety of Robotic Imaging or its
                members, employees, agents, affiliates, partners, communities or
                any other person.
              </p>
              <p>
                If it is determined that you retain moral rights (including
                rights of attribution or integrity) in the User Content, you
                hereby declare that (a) you do not require that any personal
                data, personal information, personally identifying information,
                or equivalent term be used in connection with the User Content,
                or any derivative works of or upgrades or updates thereto; (b)
                you have no objection to the publication, use, modification,
                deletion and exploitation of the User Content by Robotic Imaging
                or its licensees, successors and assigns; (c) you forever waive
                and agree not to claim or assert any entitlement to any and all
                moral rights of an author in any of the User Content; and (d)
                you forever release Robotic Imaging, and its licensees,
                successors and assigns, from any claims that you could otherwise
                assert against Robotic Imaging, or any of its assigns or
                affiliates, by virtue of any such moral rights. You also permit
                any other user to access, view, store, or reproduce the User
                Content for that user’s personal use.
              </p>
              <p>
                User Content submitted by you will be considered
                non-confidential and Robotic Imaging is under no obligation to
                treat such User Content as proprietary information. Without
                limiting the foregoing, we reserve the right to use any User
                Content as it deems appropriate, including, without limitation,
                deleting, editing, modifying, rejecting, or refusing to post it.
                Robotic Imaging is under no obligation to edit, delete or
                otherwise modify User Content once it has been submitted to us.
                We shall have no duty to attribute authorship of User Content to
                you, and shall not be obligated to enforce any form of
                attribution by third parties.
              </p>
              <p>
                Please note that we do not make any representations, warranties
                or guarantees that: (i) the Site, the Services, or any portion
                thereof, will be monitored (e.g., for accuracy or unacceptable
                use); (ii) apparent statements of fact will be authenticated; or
                (iii) we will take any specific action (or any action at all) in
                the event of a challenge or dispute regarding compliance or
                non-compliance with these Terms &amp; Conditions. We generally
                do not pre-screen any communications, content or other materials
                before it is posted, uploaded, transmitted, sent or otherwise
                made available on the Site or through the Services by users, so
                you may be exposed to certain communications, content or other
                materials that is opinionated, offensive, inappropriate, and/or
                violates these Terms &amp; Conditions.
              </p>
              <li>
                <strong>INTELLECTUAL PROPERTY</strong>
              </li>
              <p>
                The Services, the content, any materials or information
                downloaded, and all intellectual property pertaining to or
                contained on the Services (including but not limited to
                copyrights, patents, database rights, graphics, designs, text,
                logos, trade dress, trademarks, and service marks) are owned by
                Robotic Imaging or third parties; all rights, title, and
                interest will remain the property of Robotic Imaging and/or such
                third-party owner, as applicable. All content is protected by
                trade dress, copyright, patent, and trademark laws, as well as
                various other intellectual property and unfair competition laws.
              </p>
              <p>
                You are authorized to view and retain a copy of pages of the
                Services only for your own personal, non-commercial use. You may
                also view and make copies of relevant documents, pages, images,
                or other materials on the Services for the purpose of
                transacting business with us. You may not tokenize copies of the
                pages of the Services or any other content on the Services. You
                agree that you will not duplicate, publish, modify, create
                derivative works from, participate in the transfer of, or in any
                way distribute or exploit the Services, or any portion of the
                Services, for any public or commercial use, without our prior
                express written consent. Additionally, you agree that you:
                (a) will not remove or alter any author, trademark, other
                proprietary notice, or legend displayed on the Services (or
                printed pages produced from the Services), and (b) will not make
                any other modifications to any documents obtained from the
                Services other than in connection with completing information
                required to transact business with Robotic Imaging.
              </p>
              <li>
                <strong>INTELLECTUAL PROPERTY INFRINGEMENT</strong>
              </li>
              <p>
                Robotic Imaging respects the intellectual property rights of
                others, and we ask you to do the same. Robotic Imaging may, in
                appropriate circumstances and at our discretion, terminate
                service and/or access to the Services to users who infringe the
                intellectual property rights of others. If you believe that your
                work is the subject of copyright infringement and/or trademark
                infringement and appears on the Site and/or in the Services,
                please provide Robotic Imaging’s designated agent the following
                information:
              </p>
              <ol type="a">
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed.
                </li>
                <li>
                  Identification of the copyrighted and/or trademarked work
                  claimed to have been infringed, or if multiple works at a
                  single online location are covered by a single notification, a
                  representative list of such works at that location.
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled at
                  the Site, and information reasonably sufficient to permit
                  Robotic Imaging to locate the material.
                </li>
                <li>
                  Information reasonably sufficient to permit Robotic Imaging to
                  contact you as the complaining party, such as an address,
                  telephone number, and, if available, an e- mail address at
                  which you may be contacted.
                </li>
                <li>
                  A statement that you have a good-faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright and/or trademark owner, its agent, or the law.
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </li>
              </ol>
              <p>
                Robotic Imaging’s agent for notice of claims of copyright or
                trademark infringement can be reached at{" "}
                <a
                  href="mailto:nick@roboticimaging.com"
                  style={{ color: "#ffb310" }}
                >
                  nick@roboticimaging.com
                </a>
                .
              </p>
              <p>
                Please also note that for copyright infringements under Section
                512(f) of the Copyright Act, any person who knowingly materially
                misrepresents that material or activity is infringing may be
                subject to liability.
              </p>
              <p>
                <i>Submitting a DMCA Counter-Notification</i>
              </p>
              <p>
                We will notify you that we have removed or disabled access to
                copyright-protected material that you provided if such removal
                is pursuant to a valid Digital Millennium Copyright Act, 17 USC
                Section 512 et seq. (<strong>“DMCA”</strong>) take-down notice that we have
                received. If you receive such notice from us, you may provide us
                with a counter-notification, in writing, to Robotic Imaging’s
                designated agent that includes all of the following information:
              </p>
              <ol type="a">
                <li>Your physical or electronic signature;</li>
                <li>
                  Identification of the material that has been removed or to
                  which access has been disabled, and the location at which the
                  material appeared before it was removed or access to it was
                  disabled;
                </li>
                <li>
                  A statement from you, under penalty of perjury, that you have
                  a good-faith belief that the material was removed or disabled
                  as a result of a mistake or misidentification of the material
                  to be removed or disabled; and
                </li>
                <li>
                  Your name, physical address, and telephone number, and a
                  statement that you consent to the jurisdiction of a court for
                  the judicial district in which your physical address is
                  located, or if your physical address is outside of the United
                  States, for any judicial district in which Robotic Imaging may
                  be located, and that you will accept service of process from
                  the person who provided notification of allegedly infringing
                  material or an agent of such person.
                </li>
              </ol>
              <p>
                <i>Termination of Infringers</i>
              </p>
              <p>
                Robotic Imaging reserves the right, at its sole discretion, to
                terminate the Account or access of any user of the Site who is
                the subject of DMCA or other infringement notifications
              </p>
              <li>
                <strong>AVAILABILITY</strong>
              </li>
              <p>
                We cannot and do not promise that the Site or the Services will
                be uninterrupted, secure, or error-free. We reserve the right to
                interrupt/suspend this Site or the Services, or any part
                thereof, with or without prior notice for any reason.
              </p>
              <li>
                <strong>ACCESS TO THE INTERNET</strong>
              </li>
              <p>
                You are solely responsible for obtaining and maintaining all
                internet, computer hardware and other equipment needed to access
                and use this Site and the Services, and you shall be solely
                responsible for all charges and fees related thereto.
              </p>
              <p>
                Please note that the Internet is not a fully secure medium and
                any communication may be lost, intercepted or altered. Robotic
                Imaging is not liable for any damages related to communications
                to, or from, this Site or the Services.
              </p>
              <li>
                <strong>CURRENCY OF SITE</strong>
              </li>
              <p>
                Robotic Imaging cannot and does not guarantee or accept any
                responsibility or liability for the accuracy, currency or
                completeness of the information on this Site and the Services.
                We have the right, but not the obligation, to correct any
                errors, inaccuracies or omissions and to change or update this
                Site (including the Robotic Imaging Materials) and the Services
                at any time, without prior notice to you.
              </p>
              <li>
                <strong>LINKS TO OTHER SITES AND SERVICES</strong>
              </li>
              <p>
                This Site or the Services may contain links to outside services
                and resources, the availability and content of which Robotic
                Imaging does not control. The inclusion of these links does not
                imply that Robotic Imaging monitors or endorses these sites. We
                are not responsible for examining or evaluating, and we do not
                warrant the offering of these services and resources or the
                content of these websites. We do not assume any responsibility
                or liability for the actions, products, and content of these and
                any other websites. Any concerns regarding any such services or
                resources should be directed to the service or resource.
              </p>
              <li>
                <strong>SECURITY</strong>
              </li>
              <p>
                Robotic Imaging makes no warranty whatsoever to you, express or
                implied, regarding the security of the Site, including with
                respect to the ability of unauthorized persons to intercept or
                access information transmitted by you through the Site. Robotic
                Imaging is not responsible for any losses resulting from the
                loss or theft of your device, the loss or theft of your
                information transmitted from or stored on your devices, or any
                losses arising from the use of your device by someone whom you
                have given access. You are required to notify us of any
                unauthorized use of the Site by using the contact information in
                the <strong>“Contact Information”</strong> section below.
              </p>
              <p>
                Due to technical difficulties with the Internet, internet
                software or transmission problems could produce inaccurate or
                incomplete copies of information contained on this Site or the
                Services. Due to the ability to share certain content and
                materials, computer viruses or other destructive programs may
                also be inadvertently downloaded from this Site or the Services.
                Robotic Imaging recommends that you install appropriate
                anti-virus or other protective software.
              </p>
              <p>
                ROBOTIC IMAGING SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY
                SOFTWARE, COMPUTER VIRUSES OR OTHER DESTRUCTIVE, HARMFUL OR
                DISRUPTIVE FILES OR PROGRAMS THAT MAY INFECT OR OTHERWISE IMPACT
                YOUR USE OF YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT
                OF YOUR ACCESS TO, USE OF, OR BROWSING ON THE SITE OR THE
                SERVICES OR YOUR DOWNLOADING OF ANY USER MATERIALS OR OTHER
                CONTENT FROM THIS WEBSITE.
              </p>
              <li>
                <strong>SEVERABILITY/NO WAIVER</strong>
              </li>
              <p>
                If any provision of these Terms &amp; Conditions is found to be
                unenforceable, then that provision shall be deemed severable
                from these Terms &amp; Conditions and shall not affect the
                validity and enforceability of any remaining provisions. Robotic
                Imaging’s failure to enforce the strict performance of any
                provision of these Terms or the additional terms and conditions
                for any Service will not constitute a waiver of our right to
                subsequently enforce such provision or any other provisions of
                these Terms or the additional terms and conditions.
              </p>
              <p>
                Sections 15 (Security), 17 (Disclaimers: Site and Services and
                Materials Provided “As-Is”), 18 (Limitation of Liability), 19
                (Indemnification), 20 (Term and Termination), and 21
                (Arbitration Provision and Class Action Waiver) will survive any
                termination of expiry of these Terms &amp; Conditions.
              </p>
              <li>
                <strong>
                  DISCLAIMERS: SITE, SERVICES AND MATERIALS PROVIDED “AS-IS”
                </strong>
              </li>
              <p>
                THIS SITE, THE SERVICES AND THE ROBOTIC IMAGING MATERIALS ARE
                PROVIDED “AS IS,” “WHERE IS,” AND “WITH ALL FAULTS,” WITHOUT
                REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND, EITHER
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                NON- INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE. ROBOTIC
                IMAGING DOES NOT REPRESENT OR WARRANT THAT THIS SITE, THE
                SERVICES, OR THE ROBOTIC IMAGING MATERIALS WILL MEET YOUR
                REQUIREMENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                OBTAINED BY YOU FROM ROBOTIC IMAGING, OR THROUGH THE SITE SHALL
                CREATE ANY WARRANTY REGARDING THE SERVICES NOT EXPRESSLY STATED
                IN THESE TERMS. YOU UNDERSTAND AND ACKNOWLEDGE THAT ADDITIONAL
                DISCLAIMERS, LIMITATIONS, AND NOTICES REGARDING THE SERVICES AND
                ITS CONTENT AND DATA MAY BE PROVIDED BY ROBOTIC IMAGING FROM
                TIME TO TIME WITHIN THE SERVICES.
              </p>
              <p>
                These Terms were written in English (US). Any translated version
                is provided solely for your convenience using third party
                translation services such as Google Translate. You understand
                and agree that Robotic Imaging in no way controls or is
                responsible for any third-party product or service accessed
                through the Site.
              </p>
              <p>
                YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. THE SITE IS
                INTENDED FOR GENERAL INFORMATIONAL PURPOSES ONLY. WE MAKE NO
                REPRESENTATION OR WARRANTY REGARDING THE ACCURACY OF
                TRANSLATIONS OF THE SERVICES. PLEASE SEEK INDEPENDENT
                TRANSLATION SERVICES TO VERIFY ANY INFORMATION ON WHICH YOU
                RELY. NOTHING STATED, POSTED, OR AVAILABLE THROUGH ANY SERVICES
                IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, AN OFFICIAL AND
                VERIFIED TRANSLATION OF THE SERVICES.
              </p>
              <p>
                WE DO NOT WARRANT THAT THE WEBSITE AND ITS SERVERS ARE FREE OF
                COMPUTER VIRUSES OR OTHER HARMFUL CONTENT. IF YOUR USE OF THE
                SERVICES, THE MATERIAL, OR THE SOFTWARE RESULTS IN THE NEED FOR
                SERVICING OR REPLACING EQUIPMENT OR DATA, WE ARE NOT RESPONSIBLE
                FOR THOSE COSTS.
              </p>
              <p>
                Please note that some jurisdictions do not allow the disclaimer
                of certain representations or warranties, so the disclaimer in
                this Section 17 will apply to you to the extent permitted by
                applicable law.
              </p>
              <li>
                <strong>LIMITATION OF LIABILITY</strong>
              </li>
              <p>
                YOU AGREE THAT NONE OF THE PROTECTED PARTIES (AS DEFINED BELOW)
                SHALL HAVE ANY LIABILITY TO YOU UNDER ANY THEORY OF LIABILITY OR
                INDEMNITY IN CONNECTION WITH YOUR USE OF THE SITE AND/OR
                SERVICES. YOU HEREBY RELEASE AND FOREVER WAIVE ANY AND ALL
                CLAIMS YOU MAY HAVE AGAINST THE PROTECTED PARTIES (INCLUDING BUT
                NOT LIMITED TO CLAIMS BASED UPON THE NEGLIGENCE OF ANY OF THE
                PROTECTED PARTIES) FOR LOSSES OR DAMAGES YOU SUSTAIN IN
                CONNECTION WITH YOUR USE OF THE SITE AND/OR SERVICES.
              </p>
              <p>
                NOTWITHSTANDING THE FOREGOING PARAGRAPH AND TO THE MAXIMUM
                EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF THE PROTECTED
                PARTIES, FOR ANY CLAIM, COST, DAMAGE, OR LOSS ARISING OUT OF OR
                RELATING TO THE SERVICES, INCLUDING FOR ANY WARRANTIES THAT MAY
                NOT BE EXCLUDED, SHALL NOT EXCEED THE LESSER OF THE AMOUNT YOU
                PAID TO US DURING THE TWELVE (12) MONTHS PRIOR TO THE EVENT
                GIVING RISE TO THE LIABILITY OR $10.00 (TEN DOLLARS) (USD) (THE
                <strong>“LIABILITY CAP”</strong>). ROBOTIC IMAGING AND YOU AGREE THAT THE
                LIABILITY CAP SHALL INCLUDE ALL FORMS OF DAMAGES.
              </p>
              <p>
                Some jurisdictions do not allow the exclusion or limitation of
                liability for certain types of damages. In such jurisdictions,
                our liability is limited to the greatest extent permitted by
                law.
              </p>
              <li>
                <strong>INDEMNIFICATION</strong>
              </li>
              <p>
                You will defend, indemnify, and hold Robotic Imaging, each
                third-party service provider, their respective affiliates,
                subsidiaries, and parent companies, and their respective
                officers, directors, employees, agents, information providers,
                and partners (collectively, the <strong>“Protected Parties”</strong>) harmless
                from and against any actual or threatened suit, actions,
                proceedings (at law or in equity), claims, damages, payments,
                deficiencies, fines, judgments, settlements, liabilities,
                losses, costs, and expenses (including reasonable attorneys’
                fees, costs, penalties, interest, and disbursements) arising
                from or related to (a) your conduct with respect to the Services
                and/or (b) violation (or alleged violation) of these Terms by
                you or any other person accessing the Site or Services on your
                behalf. Under no circumstance will Robotic Imaging be liable for
                damages of any kind that result from your use of, or the
                inability to use, the Services.
              </p>
              <p>
                Please note that some jurisdictions limit the extent to which a
                party may indemnify another. As such, this provision will apply
                to you to the extent permitted by applicable law.
              </p>
              <li>
                <strong>TERM &amp; TERMINATION</strong>
              </li>
              <p>
                Robotic Imaging may, from time to time, but is in no way
                obligated to, permit you to access and use this Site and the
                Services in accordance with these Terms &amp; Conditions and in
                the manner more particularly set out herein. You acknowledge and
                agree (i) that access to this Site and the Services may not be
                available from time to time, may be amended, revised, replaced,
                suspended or terminated in whole or in part at any time and
                without notice, and (ii) that Robotic Imaging shall not, in any
                event, be responsible to you in any way should you be unable to
                access this Site and the Services at any time or from time to
                time. We may terminate these Terms &amp; Conditions for
                convenience with no notice to you.
              </p>
              <p>
                Upon termination of these Terms &amp; Conditions, you shall
                immediately cease and desist from all use of this Site and the
                Services.
              </p>
              <li>
                <strong>ARBITRATION PROVISION AND CLASS ACTION WAIVER</strong>
              </li>
              <p>
                THIS ARBITRATION PROVISION AND CLASS ACTION WAIVER (THE
                <strong>“ARBITRATION PROVISION”</strong>) AFFECTS YOUR RIGHTS; PLEASE READ
                CAREFULLY BEFORE AGREEING TO THESE TERMS &amp; CONDITIONS.
              </p>
              <p>
                <strong>Agreement to Arbitrate</strong>. Except as otherwise explicitly provided
                in this Arbitration Provision, Robotic Imaging, together with
                its parent companies, wholly or majority-owned subsidiaries,
                affiliates, commonly-owned companies, successors, assigns, and
                any of these entities’ employees, officers, directors, and
                agents and their successors, assigns, affiliates, and service
                providers (collectively, the <strong>“Transaction Parties”</strong>) and you can
                elect to resolve any past, present, or future dispute or claim
                ( <strong>“Dispute”</strong>) arising from or relating in any way to (i) the use
                of the Services, (ii) these Terms &amp; Conditions, or (iii) the
                relationship between you and any Transaction Party in connection
                with any of the foregoing that cannot be resolved directly
                between you and a Transaction Party, by binding arbitration
                under the Consumer Arbitration Rules (<strong>“the Consumer Rules”</strong>) of
                the American Arbitration Association (<strong>“AAA”</strong>), rather than in
                court. (Solely for purposes of this Arbitration Provision, the
                term Transaction Parties also includes any third party providing
                any goods and/or services in connection with the use of the Site
                and the Services or any of the foregoing on behalf of a
                Transaction Party, if that third party is named as a defendant
                along with a Transaction Party in the same proceeding or a
                related proceeding.)
              </p>
              <p>
                <strong>“Dispute” Defined</strong>. Except as otherwise explicitly provided in
                this Arbitration Provision, <strong>“Dispute”</strong> broadly includes, without
                limitation: any claims based in contract, statute, constitution,
                ordinance, tort, fraud, consumer rights, misrepresentation,
                equity, or any other legal theory; initial claims,
                counterclaims, cross-claims, and third-party claims; federal,
                state, and local claims; and claims which arose before the date
                of your use of the Services, including, but not limited to, any
                dispute or claim arising before the date you accessed the Site
                or the Services or agreed to these Terms &amp; Conditions and
                any dispute or claim relating to (by way of example and not
                limitation): (i) the use, denial, or termination of the Services
                and/or the events leading up thereto; (ii) any disclosure,
                advertisement, application, solicitation, promotion, or oral or
                written statement, warranty, or representation made by or on
                behalf of a Transaction Party; (iii) any product or service
                provided by or through a Transaction Party or third parties in
                connection with the use of the Services or the relationship
                between you and a Transaction Party and any associated fees;
                (iv) a Transaction Party’s use or failure to protect any
                personal information you give a Transaction Party in connection
                with the use of the Services or your relationship with the
                Transaction Party; (v) enforcement of any and all of the
                obligations a party may have to another party in connection with
                the use of the Services or agreement governing the same; or
                (viii) compliance with applicable laws and/or regulations.
              </p>
              <p>
                <strong>Exceptions to “Dispute.”</strong> Notwithstanding any of the foregoing:
                (i) disputes or controversies about the validity,
                enforceability, coverage, or scope of this Arbitration Provision
                or any part thereof are for a court and not an arbitrator to
                decide; however, disputes or controversies about these Terms
                &amp; Conditions or your agreements governing the use of the
                Services or with Transaction Parties as a whole are for an
                arbitrator and not a court to decide; (ii) any Disputes seeking
                to enforce or protect, or concerning the validity of
                intellectual property rights, will not be subject to binding
                arbitration under this Arbitration Provision; and (iii) any
                party may proceed with their individual claims in small claims
                court (or an equivalent court) if that option is available in
                the applicable jurisdiction and the amount in controversy falls
                within the small claims court’s (or the equivalent court’s)
                jurisdictional limits; but if that action is transferred,
                removed, or appealed to a different court, arbitration can be
                elected. Moreover, this Arbitration Provision will not apply to
                any Dispute that was already pending in court before this
                Arbitration Provision took effect.
              </p>
              <p>
                <strong>Governing Law.</strong> The Federal Arbitration Act (<strong>“FAA”</strong>), 9 U.S.C. 1
                et seq., and federal arbitration law apply to this Arbitration
                Provision. The parties further agree that, in order to bring a
                claim in arbitration, they must first be able to satisfy the
                federal standing requirement under Article III of the United
                States Constitution with respect to their claims, and that the
                arbitrator shall be empowered to make a binding determination on
                standing. There is no judge or jury in arbitration and court
                review of an arbitration award is limited, but an arbitrator can
                award an individual the same damages and relief as a court in an
                individual case and must apply and follow applicable substantive
                law, consistent with the FAA, and the terms of these Terms &amp;
                Conditions and any agreement governing the use of the Site and
                the Services. The arbitrator shall apply applicable statutes of
                limitations and honor privilege rules. Any judgment on the award
                rendered by the arbitrator will be final, subject to any appeal
                rights under the FAA, and may be entered in any court of
                competent jurisdiction. No arbitration award involving the
                parties will have any preclusive effect as to issues or claims
                in any dispute involving anyone who is not a party to the
                arbitration, nor will an arbitration award in prior disputes
                involving other parties have preclusive effect in an arbitration
                between the parties to this Arbitration Provision.
              </p>
              <p>
                <strong>JURY TRIAL WAIVER. YOU UNDERSTAND THAT ABSENT YOUR CONSENT TO
                ARBITRATION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A
                TRIAL BY JURY.</strong>
              </p>
              <p>
                <strong>Arbitration Procedures.</strong> Either you or a Transaction Party can
                initiate arbitration through the AAA or by filing a motion to
                compel arbitration of claims filed in court. Regardless of who
                elected arbitration or how arbitration was elected, the party
                asserting the claim (i.e., the party seeking money damages or
                other relief from a court or an arbitrator) is responsible for
                starting the arbitration proceeding. If the AAA cannot serve and
                we cannot agree on a substitute, a court with jurisdiction shall
                select the arbitrator, who will apply the AAA rules and the
                procedures specified in this Arbitration Provision. Any
                arbitrator must be a practicing attorney with ten or more years
                of experience practicing law or a retired judge. Any arbitration
                will be governed by the then-current Consumer Rules, and its
                Procedures for the Resolution of Disputes through Document
                Submission (<strong>“Document Submission Procedures”</strong>). For more
                information about the AAA and its rules, you may contact the AAA
                at American Arbitration Association, 120 Broadway, Floor 21, New
                York, N.Y. 10271, 1-800-778-7879,{" "}
                <a href="www.adr.org" style={{ color: "#ffb310" }}>
                  www.adr.org.
                </a>
                The Document Submission Procedures are included in the Consumer
                Rules. The parties shall pay filing, administrative and
                arbitrator fees in accordance with the Consumer Rules. If you
                cannot afford to pay your share of the fees and cannot obtain a
                waiver from the AAA, you can make a written good faith request
                for a Transaction Party to pay or advance such fees. Each party
                will bear the expense of its own attorneys, experts and
                witnesses, regardless of which party prevails, unless applicable
                law, these Terms &amp; Conditions or an applicable agreement
                gives a party the right to recover such expenses from the other
                party. If the arbitrator determines that any party’s claim or
                defense is frivolous or wrongfully intended to oppress or harass
                the other party, the arbitrator may award sanctions in the form
                of fees and expenses reasonably incurred by the other party if
                such sanctions could be imposed under Rule 11 of the Federal
                Rules of Civil Procedure. The arbitration will be conducted by a
                single arbitrator solely based on written submissions and will
                not require any personal appearance by the parties or witnesses
                unless the arbitrator determines that a telephonic or in-person
                conference or hearing is necessary based on the request of one
                or more of the parties. If an in-person hearing is necessary, it
                will be held in the federal judicial district in which you
                reside or at another location that is reasonably convenient to
                all parties.
              </p>
              <p>
                <strong>CLASS ACTION WAIVER.</strong> You and the Transaction Parties each agree
                that if arbitration of a Dispute is elected, the Dispute will be
                resolved in arbitration (not in court) only on an individual
                basis and not as a class, collective or other representative
                action. To the fullest extent permitted under the FAA: (a) no
                arbitration will be joined or consolidated with any other unless
                all parties otherwise agree in writing; (b) there is no right or
                authority for any Dispute to be arbitrated on a class-action,
                collective action or private attorney general basis or to
                utilize class action procedures; and (c) there is no right or
                authority for any Dispute to be brought in a purported
                representative capacity on behalf of the general public or any
                other persons. No arbitrator shall have the authority to issue
                any relief that applies to any person or entity other than
                Transaction Parties and/or you individually.
              </p>
              <p>
                <strong>Miscellaneous; Conflicts.</strong> Even if all parties have opted to
                litigate a Dispute in court, a party may elect arbitration with
                respect to any claim made by a new party or any claim later
                asserted by a party in that or any related or unrelated lawsuit
                (including a claim initially asserted on an individual basis but
                modified to be asserted on a class, representative, or
                multi-party basis). Nothing in that litigation shall constitute
                a waiver of any rights under this Arbitration Provision. If any
                portion of this Arbitration Provision is inconsistent with the
                Consumer Rules, with these Terms &amp; Conditions, or your
                agreements governing the use of the Services, or with an
                arbitration provision in any agreement with a Transaction Party,
                this Arbitration Provision shall govern.
              </p>
              <p>
                <strong>Survival; Severance.</strong> This Arbitration Provision shall survive
                termination of the Site and the Services or these Terms &amp;
                Conditions or of any agreement into which you enter with a
                Transaction Party; and, further, you understand and agree that
                this Arbitration Provision applies not only to these Terms &amp;
                Conditions but also to any subsequent agreement (including
                without limitation any agreement governing the use of Site or
                Services) into which you enter with a Transaction Party. If any
                portion of this Arbitration Provision is deemed invalid or
                unenforceable, it shall not invalidate the remaining portions of
                this Arbitration Provision, except that: (a) if the Class Action
                Waiver is limited, voided or found unenforceable with respect to
                a Dispute that does not seek public injunctive relief and that
                determination becomes final after all appeals have been
                exhausted, then this Arbitration Provision (except for this
                sentence) shall be null and void with respect to such
                proceeding. The parties acknowledge and agree that under no
                circumstances will a class action be arbitrated; and (b) if a
                claim is brought seeking public injunctive relief and a court
                determines that the restrictions in the Class Action Waiver or
                elsewhere in this Arbitration Provision prohibiting the
                arbitrator from awarding relief on behalf of third parties are
                unenforceable with respect to such claim and that determination
                becomes final after all appeals have been exhausted, the claim
                for public injunctive relief will be determined in court and any
                individual claims seeking monetary relief will be arbitrated. In
                such a case the parties will request that the court stay the
                claim for public injunctive relief until the arbitration award
                pertaining to individual relief has been entered in court. In no
                event will a claim for public injunctive relief be arbitrated.
              </p>
              <p>
                <strong>
                RIGHT TO OPT OUT: If you do not want this Arbitration Provision
                to apply, you must send us a signed notice within thirty (30)
                calendar days of the date on which you electronically submit an
                application for a product or service offered by a Transaction
                Party through the Site or Services. You must send the notice in
                writing (and not electronically) to Robotic Imaging, Inc. Attn:
                Nick Chawaga, 2401 Walnut St, Suite 101, Philadelphia, PA 19103. You
                must provide your name, address, telephone number, and state
                that you “opt out” of the Arbitration Provision. Opting out will
                not affect the other provisions of these Terms &amp; Conditions
                or any other agreement governing the Site or with a Transaction
                Party. If you do not opt out, you will be bound by this
                Arbitration Provision in these Terms &amp; Conditions and any
                other agreement governing a Service or with a Transaction Party.
                Please note that if you enter an agreement with a Transaction
                Party that contains a different arbitration provision with a
                right to opt out, opting out of this Arbitration Provision is
                not a rejection of the arbitration provision in the other
                agreement. You will need to separately opt out of the
                arbitration provision in the other agreement if you do not want
                it to apply.</strong>
              </p>
              <li>
                <strong>CHANGES</strong>
              </li>
              <p>
                You are responsible for reviewing these Terms regularly. Robotic
                Imaging reserves the right, at any time, without notice to you,
                and in its sole discretion, to modify or discontinue the
                Services, these Terms, or any of our policies related to use of
                the Services. We will not amend the Arbitration Provision or
                Class Action Waiver, described below, in a manner that adversely
                affects your rights or responsibilities in a material manner
                unless we give you a right to reject the amendment and/or the
                Arbitration Provision and Class Action Waiver in its entirety.
                Revisions to these Terms or our policies may be provided through
                the Services, including by posting the revisions on the Services
                and updating the “Last Updated” date. Such revisions will go
                into immediate effect once posted to the Services. Continued use
                of the Services following such modifications to the Services,
                these Terms, additional terms and conditions for any service, or
                our other policies will constitute your acceptance of such
                modifications and revisions.
              </p>
              <li>
                <strong>MISCELLANEOUS</strong>
              </li>
              <p>
                These Terms constitute the entire agreement of the parties with
                respect to the subject matter hereof and supersede all previous
                written or oral agreements between the parties with respect to
                such subject matter. No waiver by either party of any breach or
                default hereunder shall be deemed to be a waiver of any
                preceding or subsequent breach or default. The section headings
                used in these Terms shall not be given any legal import.
              </p>
              <p>
                These Terms &amp; Conditions shall be governed by and construed
                in accordance with the laws of the State of Delaware (without
                regard to provisions relating to conflicts of law), except as to
                copyright and trademark matters, which are covered by American
                federal laws. You agree that any legal action or proceeding
                between Robotic Imaging and you for any purpose concerning these
                Terms or the parties’ obligations hereunder shall be resolved
                individually, without resort to any form of class action,
                exclusively in the United States District Court for Delaware,
                or, if there is not subject matter jurisdiction, in the courts
                of the State of Delaware, and you agree to submit to the
                jurisdiction of these courts. You waive any venue, personal
                jurisdiction, and inconvenient forum objections or defenses to
                such courts.
              </p>
              <p>
                These Terms &amp; Conditions are not assignable, transferable,
                or sublicensable by you except with Robotic Imaging’s prior
                written consent.
              </p>
              <p>
                Robotic Imaging may assign its rights and duties under these
                Terms &amp; Conditions at any time without notice to you.
              </p>
              <p>
                No waiver by either party of any breach or default hereunder
                shall be deemed to be a waiver of any preceding or subsequent
                breach or default.
              </p>
              <li>
                <strong>CONTACT INFORMATION</strong>
              </li>
              <p>
                If you need to contact us regarding this Site, the Services or
                these Terms &amp; Conditions, please reach out to us:
              </p>
              <p>By mail: 2401 Walnut St, Suite 101, Philadelphia, PA 19103</p>
              <p>
                By phone:{" "}
                <a href="tel:12679691153" style={{ color: "#ffb310" }}>
                  +1 267-969-1153
                </a>
              </p>
              <p>
                By email:{" "}
                <a
                  href="mailto:info@roboticimaging.com"
                  style={{ color: "#ffb310" }}
                >
                  info@roboticimaging.com
                </a>
              </p>
            </ol>
          </div>
        </div>
      </main>
    </SiteWrapper>
  );
}
